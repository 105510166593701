import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const imageContainerStyles = {
  margin: '8px',
  width: 'fit-content',
  height: '320px',
  overflow: 'hidden',
};

const GalleryPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      file1: file(relativePath: { eq: "view-from-the-garden.png" }) {
        childImageSharp {
          fixed(width: 440) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      file2: file(relativePath: { eq: "sunset_on_the_trail.jpg" }) {
        childImageSharp {
          fixed(width: 440) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      file3: file(relativePath: { eq: "god_my_rock.jpg" }) {
        childImageSharp {
          fixed(width: 440) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout location={location}>
      <Seo title='Artwork' />

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          margin: '0 auto',
        }}
      >
        <div style={imageContainerStyles}>
          <Img fixed={data.file1.childImageSharp.fixed} />
        </div>
        <div style={imageContainerStyles}>
          <Img fixed={data.file2.childImageSharp.fixed} />
        </div>
        <div style={imageContainerStyles}>
          <Img fixed={data.file3.childImageSharp.fixed} />
        </div>
      </div>
      <a
        href='https://carolnordman.artspan.com/carousel.php'
        target='_blank'
        rel='noopener noreferrer'
        style={{
          color: 'white',
          textDecoration: 'none',
          margin: '1rem auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2
          className='my-button'
          style={{
            marginTop: '0',
            marginBottom: '0',
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: '400',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          View my full gallery on Artspan&nbsp;
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5'
            viewBox='0 0 20 20'
            fill='currentColor'
            style={{ height: '1.5rem' }}
          >
            <path d='M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z' />
            <path d='M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z' />
          </svg>
        </h2>
      </a>
    </Layout>
  );
};

export default GalleryPage;
